<template>
	<div class="container">
		<div class="event-card">
			<h4>{{ event ? event.name : "" }}</h4>
			<div class="status">
				<span
					:class="
						statusColor == '未知'
							? ''
							: statusColor == '在線'
							? 'statusGreen'
							: 'statusRed'
					"
				></span>
				<span>狀態: {{ event ? statusColor : "" }}</span>
			</div>
			<img :src="event ? event.image : ''" />
			<nav class="nav-details">
				<router-link :to="{ name: 'EventDetails' }">介紹</router-link>
				|
				<router-link :to="{ name: 'EventLocation' }"
					>聯絡方式</router-link
				>
			</nav>
			<router-view :event="event" />
		</div>
	</div>
</template>
<script>
import EventService from "@/services/EventService.js";
export default {
	props: ["id"],
	data() {
		return {
			event: null,
			statusColor: null
		};
	},
	created() {
		EventService.getEvent(this.id)
			.then((response) => {
				this.event = response.data;
				if (this.event.status == "Alive") {
					this.statusColor = "在線";
				} else if (this.event.status == "Unknow") {
					this.statusColor = "未知";
				} else {
					this.statusColor = "離線";
				}
				// this.statusColor = this.event.status;
				console.log(this.statusColor);
			})
			.catch((error) => {
				console.log(error);

				this.$router.push({
					name: "404Resource",
					params: { resource: "event" }
				});
			});
	}
};
</script>

<style scope>
.event-card {
}
.statusGreen,
.statusRed {
	margin: auto 0;
	height: 0.5rem;
	width: 0.5rem;
	margin-right: 0.375rem;
	background: rgb(214, 61, 46);
	border-radius: 50%;
}
.statusGreen {
	background: rgb(92 199 12);
}
</style>
