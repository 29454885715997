import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
//import "nprogress/nprogress.css";

/* import the fontawesome core */
import { library } from "@fortawesome/fontawesome-svg-core";

/* import font awesome icon component */
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

/* import all icons */
import { faLine, faInstagram } from "@fortawesome/free-brands-svg-icons";

/* add icons to the library */
library.add(faInstagram);
library.add(faLine);

createApp(App)
	.component("font-awesome-icon", FontAwesomeIcon)
	.use(router)
	.mount("#app");
