<template>
	<p>
		<font-awesome-icon
			icon="fa-brands fa-line"
			size="2xl"
			style="color: #00f900"
		/>
	</p>
	<p>
		<font-awesome-icon
			icon="fa-brands fa-instagram"
			size="2xl"
			style="color: #ff2600"
		/>
	</p>
</template>

<script>
export default {
	props: ["event"]
};
</script>
